<template>
    <div>
        <JqxWindow
            ref="myModal"
            :width="300"
            :height="'auto'"
            :position="{ x: positionX, y: positionY }"
            :minWidth="500"
            :minHeight="200"
            :keyboardCloseKey="27"
            @close="onClose($event)"
        >
            <div>{{ title }}</div>
            <div class="content" style="white-space: pre-line">
                {{ content }}
                <div class="footer">
                    <input type="checkbox" :value="noticeNo" v-model="isHideModal" />
                    <label for="disableShow">다시보지않기</label>
                    <div class="btn" @click="closeModal">닫기</div>
                </div>
            </div>
        </JqxWindow>
    </div>
</template>

<script>
import JqxWindow from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxwindow.vue';
import TokenService from '@/api/tokenService';

export default {
    components: {
        JqxWindow,
    },
    props: {
        noticeNo: {
            type: String,
            Required: true,
        },
        title: {
            type: String,
            Required: true,
        },
        content: {
            type: String,
            Required: true,
        },
        positionX: {
            type: Number,
            default: 250,
        },
        positionY: {
            type: Number,
            default: 100,
        },
    },
    data: function () {
        return {
            isHideModal: '',
        };
    },
    methods: {
        onClose: function (event) {
            // 상단 x 아이콘 이벤트
            this.setLocalStorage();
            this.$emit('closeModal');
        },
        closeModal() {
            // 하단 닫기 버튼 이벤트
            this.setLocalStorage();
            //this.$refs.myModal.close();
            this.$refs.myModal.destroy();
            this.$emit('closeModal');
        },
        setLocalStorage() {
            if (this.isHideModal) {
                TokenService.setHideModalList(this.noticeNo);
            }
        },
    },
};
</script>

<style>
.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* 부모 div의 크기를 기준으로 설정 */
    padding: 10px;
    box-sizing: border-box;
    font: 400 15px / 28px 'Pretendard';
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px; /* 체크박스와 버튼 사이 간격 */
    margin-top: auto; /* 체크박스와 버튼을 맨 아래로 밀어내기 */
    font: 300 15px / 28px 'Pretendard';
}
</style>
