<template>
    <div>
        <nav style="transition: width 0.5s; overflow: hidden">
            <div class="nav-header">
                <h1><span type="button" style="cursor: pointer" @click="goHome">제지 TMS</span></h1>
                <h2>운송관리시스템</h2>
            </div>
            <JqxTree
                ref="myTree"
                :width="'202px'"
                style="float: left"
                :toggleMode="'click'"
                :allowDrag="false"
                @select="select($event)"
                @expand="expand($event)"
                @collapse="collapse($event)"
            ></JqxTree>
            <div class="nav-footer">
                <img src="/styles/images/icons/ic-profile.svg" alt="" />
                <span class="u-name">{{ user.userNm }}님</span>
                <span class="u-team">영업 1팀</span>
                <button class="btn black" @click="handleLogOut">로그아웃</button>
            </div>
        </nav>
        <section style="transition: left 0.5s">
            <div
                class="menu-panel"
                @click="toggleMenu"
                style="
                    position: absolute;
                    background-color: #616574;
                    width: 7px;
                    height: 59px;
                    border-radius: 0 15px 15px 0;
                    left: 1px;
                    cursor: pointer;
                    top: 5px;
                    z-index: 1001;
                "
            ></div>
            <div class="page-information">
                <h3>{{ menuLabel }}</h3>
                <label v-if="environment != 'production'" style="color: red"
                    >ENV : {{ environment.toUpperCase() }}</label
                >
                <p>
                    <span type="button" @click="goHome">Home</span><span>{{ parentLabel }}</span>
                </p>
            </div>
            <router-view :user="user" />
        </section>
        <div class="modal" id="loading" style="display: none">
            <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>
        <!--
        <ChangePwModal ref="changePwModal" />
        -->
    </div>
</template>

<script>
import api from '@/api/api-interceptors';
import JqxTree from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxtree.vue';
import TokenService from '@/api/tokenService';
import user from '@/store/modules/user';
//import ChangePwModal from '@/components/modal/ChangePwModal.vue';

export default {
    components: {
        JqxTree,
        //ChangePwModal,
    },
    data: function () {
        return {
            menuLabel: '대시보드',
            parentLabel: '',
            menus: [],
            parent: [],
            user: TokenService.getUser(),
            environment: process.env.VUE_APP_TITLE,
        };
    },
    mounted: function () {
        if (this.user) {
            if (this.user.initYn == true) {
                this.$refs.changePwModal.open();
            }

            // if (TokenService.getPersistedState() != undefined) {
            //     let data = JSON.parse(TokenService.getPersistedState());
            //     this.makeMenuObj(data.MENU_ITEMS);
            //     return;
            // }

            // 공통코드
            api.post('/common/init', {
                authCd: this.user.role,
            })
                .then((res) => {
                    let data = res.data;

                    this.makeMenuObj(data.menuList);
                    this.$store.commit('initCodes', {
                        bizAreaList: data.bizAreaList,
                        codeItems: data.codeList,
                        erpCodeItems: data.erpCodeList,
                        menuItems: data.menuList,
                    });
                })
                .catch((err) => {
                    console.log('/common/init : ', err);
                    if (err.returnCode == '405') {
                        this.handleLogOut();
                    }
                });

            // 권한
            api.post('/admin/auth/authList', {})
                .then((res) => {
                    this.$store.commit('setAuthList', res.data.authList);
                })
                .catch((error) => {
                    console.log(error);
                    alert(error.returnMessage);
                });
        }
    },
    methods: {
        movePage(target) {
            if (target == null) return;
            this.$router.push(target).catch((err) => err);
        },
        handleLogOut() {
            TokenService.removeUser();

            api.post('/logout')
                .then((res) => {
                    this.$router.push('/login');
                })
                .catch((err) => {
                    console.log(err.toString());
                });
        },
        goHome() {
            this.menuLabel = '대시보드';
            this.parentLabel = '';
            this.movePage('/');
            //Tree 초기화
            this.$refs.myTree.source = this.menus;
        },
        makeMenuObj(menuList) {
            this.parent = menuList.filter((item) => item.parentCd == '000000');

            for (let i = 0; i < this.parent.length; i++) {
                let parentObj = {
                    id: i,
                    label: this.parent[i].menuNm,
                    value: this.parent[i].menuUrl,
                    icon: this.parent[i].imgUrlOff,
                    iconsize: '16px',
                };
                let children = menuList
                    .filter((item) => item.parentCd == this.parent[i].menuCd)
                    .sort((a, b) => {
                        a.dispOrder - b.dispOrder;
                    });
                if (children.length > 0) {
                    let list = [];
                    for (let j = 0; j < children.length; j++) {
                        let chrObj = {
                            label: children[j].menuNm,
                            value: children[j].menuUrl,
                            iconsize: parentObj.label, // iconsize에 부모 이름을 넣어서 사용함.
                        };
                        list.push(chrObj);
                    }
                    parentObj.items = list;
                }
                this.menus.push(parentObj);
            }

            this.$refs.myTree.source = this.menus;

            // expand 세팅
            this.settingExpand();
        },
        select: function (event) {
            // let url = event.args.owner.selectedItem.value;
            let item = this.$refs.myTree.getSelectedItem();
            if (item.level === 0) return;

            this.menuLabel = item.label;
            this.parentLabel = item.iconsize;
            this.setIconActive(item.parentId);

            this.movePage(item.value);
        },
        expand: function (event) {
            let item = this.$refs.myTree.getSelectedItem();
            this.setMenuState(item, 'expand');
        },
        collapse: function (event) {
            let item = this.$refs.myTree.getSelectedItem();
            this.setMenuState(item, 'collapse');
        },
        setMenuState(item, state) {
            if (item.level !== 0) return;
            this.$store.commit('setTreeState', {
                menu: item.label,
                status: state,
            });
        },
        settingExpand() {
            const treeMenu = this.$refs.myTree;
            const state = this.$store.state.TREE_MENU_STATE;
            const items = treeMenu.getItems();

            //지우면 에러남
            let filter = items.filter((v) => v.value != null && v.value === this.$router.history.current.path);
            if (filter.length == 0) return;
            if (filter[0].name === 'home') return;
            treeMenu.selectItem(filter[0]);
            //

            for (let item of items) {
                if (item != null && item.level === 0) {
                    if (item.label in state && state[item.label] === 'expand') {
                        treeMenu.expandItem(item.element);
                    }
                }
            }
        },
        setIconActive(selected) {
            for (let i = 0; i < this.parent.length; i++) {
                var p = document.getElementById(i);
                var img = p.querySelector('img');

                if (i == selected) {
                    img.src = this.parent[i].imgUrlOn;
                } else {
                    img.src = this.parent[i].imgUrlOff;
                }
            }
        },
        toggleMenu() {
            // 네비게이션 토글 (애니메이션 적용)
            const nav = document.querySelector('body nav');
            const navDisplay = getComputedStyle(nav).display;
            const section = document.querySelector('section');

            // 네비게이션 슬라이드 토글
            if (navDisplay === 'none' || navDisplay === '') {
                nav.style.display = 'flex';
                nav.style.width = '0px'; // 초기값 설정
                setTimeout(() => {
                    nav.style.width = '223px';
                }, 10); // 약간의 지연을 주어 애니메이션 작동
                section.style.left = '223px';
            } else {
                nav.style.width = '0px';
                setTimeout(() => {
                    nav.style.display = 'none';
                }, 500); // 애니메이션 후 display를 none으로
                section.style.left = '0px';
            }
        },
    },
};
</script>

<style scoped></style>
