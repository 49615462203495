<template>
    <div>
        <div class="chart-align">
            <div class="wrap-aboutGrid">
                <button class="btn sm" @click="toggleFullScreen()">풀스크린</button>
            </div>
            <div class="chart-area-grid">
                <div class="jqx-charts-container">
                    <div class="jqx-chart-row">
                        <!-- 좌상단 바 그래프 -->
                        <JqxChart class="jqx-chart" ref="monthlyOrderChart" style="width: 40%; height: 250px" :title="'월간 주문건수'" :description="''" :enableAnimations="false" :showLegend="false" :padding="padding"
                                  :titlePadding="titlePadding" :source="monthlyCountData" :xAxis="xAxis" :valueAxis="valueAxis" :seriesGroups="seriesGroups2" :colorScheme="'scheme25'"/>
                        <!-- 우상단 파이차트 -->
                        <JqxChart class="jqx-chart" ref="dailyOrderChart" style="width: 15%; height: 250px;" :title="'금일 주문건수'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent(['A','B','C'])"
                                  :titlePadding="titlePadding" :source="dailyCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="waitChart" style="width: 15%; height: 250px;" :title="'출고대기'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent(['A'])"
                                  :titlePadding="titlePadding" :source="waitCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme04'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="onGoingChart" style="width: 15%; height: 250px;" :title="'출고중'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent(['B'])"
                                  :titlePadding="titlePadding" :source="onGoingCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme07'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                        <JqxChart class="jqx-chart" ref="completeChart" style="width: 15%; height: 250px;" :title="'출고완료'" :description="''" :showLegend="false" :enableAnimations="false" :padding="padding" @click="chartEvent(['C'])"
                                  :titlePadding="titlePadding" :source="completeCountData" :showToolTips="false" :seriesGroups="seriesGroups" :colorScheme="'scheme25'" :backgroundColor="'#FAFAFA'" :drawBefore="drawBefore"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="align">
            <div class="area-grid" style="flex: 10; width:100%; height:100%">
                <JqxGrid
                    ref="dataGrid"
                    :width="'calc(100% - 3px)'"
                    :height="480"
                    :source="dataAdapter"
                    :columns="dataColumns"
                    :columnsresize="true"
                    :altrows="true"
                    :theme="'eyh'"
                    :filterable="false"
                    :showfilterrow="false"
                    :enabletooltips="true"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import api from '@/api/api-interceptors';
    import JqxChart from "jqwidgets-scripts/jqwidgets-vue/vue_jqxchart.vue";
    import JqxGrid from 'jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid.vue';
    export default {
        components: {
            JqxChart,
            JqxGrid
        },
        watch: {
            filterArr(){
                this.filterEvent();
            }
        },
        data: function () {
            return {
                // eslint-disable-next-line
                dataAdapter: new jqx.dataAdapter(this.source),
                dataColumns: [
                    { text: '', datafield: 'platform', align: 'center', cellsalign: 'center', width: '5%', cellsrenderer: this.cellsrendererPlatform, editable: false },
                    { text: '주문채번번호', datafield: 'noJumunOrderSerial', align: 'center', cellsalign: 'center', width: '10%' ,editable: false},
                    { text: '출고계획번호', datafield: 'noTransactionPlan', align: 'center', cellsalign: 'center', width: '10%' ,editable: false},
                    { text: '차량번호', datafield: 'noCar', align: 'center', cellsalign: 'center', width: '10%', editable: false},
                    { text: '출고계획일자', datafield: 'dtDelivery', align: 'center', cellsalign: 'center', cellsformat: 'yyyy-MM-dd', width: '10%' ,editable: false},
                    { text: '납기요청일시', datafield: 'eta', align: 'center', cellsalign: 'center', width: '10%' ,editable: false},
                    { text: '납품처', datafield: 'outPartner', align: 'center', cellsalign: 'left', width: '10%' ,editable: false},
                    { text: '출고롤수', datafield: 'roleTran', align: 'center', cellsalign: 'right', width: '5%' ,editable: false, cellsformat: 'n2'},
                    { text: '출고수량', datafield: 'qtyTran', align: 'center', cellsalign: 'right', width: '5%' ,editable: false, cellsformat: 'n2'},
                    { text: '비고', datafield: 'txtRemark', align: 'center', cellsalign: 'left', width: '15%' ,editable: false},
                    { text: '상차상태', datafield: 'stsPicking', align: 'center', cellsalign: 'center', width: '5%', cellsrenderer: this.cellsrendererStsPicking, editable: false },
                    { text: '출고상태', datafield: 'status', align: 'center', cellsalign: 'center', width: '5%', cellsrenderer: this.cellsrendererStatus, editable: false },
                ],
                dailyCountData: [{ text: '전체 주문건수', value: 0 }],
                waitCountData: [{ text: '출고대기', value: 0 },{ text: '', value: 0 }],
                onGoingCountData: [{ text: '출고중', value: 0 },{ text: '', value: 0 }],
                completeCountData: [{ text: '출고완료', value: 0 },{ text: '', value: 0 }],
                monthlyCountData: [{ 기준일자: '전월', value: 0},{ 기준일자: '당월', value: 0}],
                padding: { left: 5, top: 5, right: 5, bottom: 5 },
                titlePadding: { left: 5, top: 5, right: 5, bottom: 5 },
                seriesGroups: [
                    {
                        type: 'donut',
                        useGradientColors: false,
                        series:
                            [
                                {
                                    showLabels: false,
                                    enableSelection: true,
                                    displayText: 'text',
                                    dataField: 'value',
                                    labelRadius: 120,
                                    initialAngle: 90,
                                    radius: 60,
                                    innerRadius: 40,
                                    centerOffset: 0,
                                    rotation: 'counterclockwise',
                                }
                            ]
                    }
                ],
                xAxis: {
                    dataField: '기준일자',
                    gridLines: { visible: true },
                    flip: false,
                    labels:
                        {
                            angle: 90,
                            horizontalAlignment: 'right',
                            verticalAlignment: 'center',
                            rotationPoint: 'right',
                            offset: { x: 0, y: -5 }
                        }
                },
                valueAxis: {
                    flip: true,
                    labels: {
                        visible: true,
                        formatFunction: (value) => {
                            return parseInt(value);
                        }
                    },
                    minValue: 0
                },
                seriesGroups2: [
                {
                        type: 'column',
                        orientation: 'horizontal',
                        toolTipFormatSettings: { thousandsSeparator: ',' },
                        columnsGapPercent: 70,
                        series: [
                            {
                                dataField: 'value', 
                                displayText: '주문건수', 
                                labels: {
                                    visible: true,
                                    horizontalAlignment: 'right',
                                    offset: { x: 35, y: 0 },
                                },
                                formatFunction: (value) => {
                                    return value.toLocaleString();
                                },
                            }
                        ]
                    }
                ],
                fullScreen: false,
                //실시간 갱신여부 => true
                isContinue: true,
                filterArr: []
            }
        },
        beforeCreate: function () {
            this.counter = 0;
            this.latencyThreshold = 260;
            this.source = {
                localdata: [],
                datafields: [
                    { name: 'noJumunOrderSerial', type: 'string' },
                    { name: 'noTransactionPlan', type: 'string' },
                    { name: 'noCar', type: 'string' },
                    { name: 'dtDelivery', type: 'date' },
                    { name: 'eta', type: 'string' },
                    { name: 'outPartner', type: 'string' },
                    { name: 'roleTran', type: 'number' },
                    { name: 'qtyTran', type: 'number' },
                    { name: 'stsPicking', type: 'string' },
                    { name: 'stsTran', type: 'string' },
                    { name: 'status', type: 'string' },
                    { name: 'platform', type: 'string' },
                    { name: 'txtRemark', type: 'string' }
                ],
                datatype: 'json',
            };
        },
        methods: {
            drawBefore: function (renderer, rect) {
                let value;
                if (this.counter === 0) {
                    value = this.dailyCountData[0].value;
                } else if (this.counter === 1) {
                    value = this.waitCountData[0].value;
                } else if (this.counter === 2) {
                    value = this.onGoingCountData[0].value;
                } else if (this.counter === 3) {
                    value = this.completeCountData[0].value;
                }
                let sz = renderer.measureText(value, 0, { 'class': 'jqx-chart-inner-text' });
                renderer.text(
                    value,
                    rect.x + (rect.width - sz.width) / 2,
                    rect.y + rect.height / 2,
                    0,
                    0,
                    0,
                    { 'class': 'jqx-chart-inner-text'}
                );
                this.counter ++;
            },
            retrivePerformance: function() {
                if(!this.isContinue) return false;
                api.post('/perfomance/deliveryStatus/retrivePerformance', {})
                .then((response) => {
                    let waitCount = 0;
                    let onGoningCount = 0;
                    let completeCount = 0;

                    response.data.performanceHistoryList.forEach((entity) => {
                        switch (entity.status){
                            case 'A':
                                waitCount++;
                                break;
                            case 'B':
                                onGoningCount++;
                                break;
                            case 'C':
                            completeCount++;
                                break;
                        }
                    });
                    
                    let totalCount = waitCount + onGoningCount + completeCount;

                    let lastMonthCnt = response.data.jumunOrderMonthlyCount.LAST_MONTH;
                    let thisMonthCnt = response.data.jumunOrderMonthlyCount.THIS_MONTH;

                    this.dailyCountData[0].value = totalCount;

                    this.waitCountData[0].value = waitCount;
                    this.waitCountData[1].value = onGoningCount + completeCount;

                    this.onGoingCountData[0].value = onGoningCount;
                    this.onGoingCountData[1].value = waitCount + completeCount;

                    this.completeCountData[0].value = completeCount;
                    this.completeCountData[1].value = waitCount + onGoningCount;

                    this.dailyCountData = [...this.dailyCountData];
                    this.waitCountData = [...this.waitCountData];
                    this.onGoingCountData = [...this.onGoingCountData];
                    this.completeCountData = [...this.completeCountData];
                    this.monthlyCountData = [...this.monthlyCountData];
                    this.counter = 0;

                    this.monthlyCountData[0].value = lastMonthCnt;
                    this.monthlyCountData[1].value = thisMonthCnt;

                    if(this.$refs.monthlyOrderChart) this.$refs.monthlyOrderChart.update();
                    if(this.$refs.dailyOrderChart) this.$refs.dailyOrderChart.update();
                    if(this.$refs.waitChart) this.$refs.waitChart.update();
                    if(this.$refs.onGoingChart) this.$refs.onGoingChart.update();
                    if(this.$refs.completeChart) this.$refs.completeChart.update();

                    this.source.localdata = response.data.performanceHistoryList;
                    this.$refs.dataGrid.updatebounddata('cells');
                })
                .catch((error) => {
                    // alert(error.returnMessage);
                })
                //대시보드 갱신주기
                setTimeout(() => {
                    this.retrivePerformance();
                }, 1000*10);
            },
            toggleFullScreen: function() {
                this.fullScreen = !this.fullScreen;

                let nav = document.querySelector('nav');
                let section = document.querySelector('section');
                let header = document.querySelector('.page-information');

                if(this.fullScreen){
                    header.style.display = 'none';
                    nav.style.display = 'none';
                    section.style.position = 'static';
                }else{
                    header.style.display = 'flex';
                    nav.style.display = 'flex';
                    section.style.position = 'absolute';
                }
            },
            chartEvent: function(stsArr) {
                this.filterArr = stsArr;
            },
            filterEvent: function() {
                // eslint-disable-next-line
                let filtergroup = new jqx.filter();
                let filtercondition = 'contains';
                let filter_or_operator = 1;
                this.filterArr.forEach(filterValue => {
                    let filter = filtergroup.createfilter('stringfilter', filterValue, filtercondition);
                    filtergroup.addfilter(filter_or_operator, filter);
                })

                this.$refs.dataGrid.addfilter('status', filtergroup);
                this.$refs.dataGrid.applyfilters();
            },
            cellsrendererStsPicking: function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                if(value == '미처리') return ( '<div class="jqx-grid-cell-middle-align grid-status-red">' + (value) + '</div>' );
                if(value == '부분처리') return ( '<div class="jqx-grid-cell-middle-align grid-status-yellow">' + (value) + '</div>' );
                if(value == '완전처리') return ( '<div class="jqx-grid-cell-middle-align grid-status-green">' + (value) + '</div>' );
            },
            cellsrendererStatus: function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                if(value == 'A') return ( '<div class="jqx-grid-cell-middle-align grid-status-red">' + '출고대기' + '</div>' );
                if(value == 'B') return ( '<div class="jqx-grid-cell-middle-align grid-status-yellow">' + '출고중' + '</div>' );
                if(value == 'C') return ( '<div class="jqx-grid-cell-middle-align grid-status-green">' + '출고완료' + '</div>' );
            },
            cellsrendererPlatform: function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                if(value == 'ERP') return ( '<div class="jqx-grid-cell-middle-align grid-status-yellow">' + (value) + '</div>' );
                if(value == 'TMS') return ( '<div class="jqx-grid-cell-middle-align grid-status-green">' + (value) + '</div>' );
            },
        },
        mounted() {
            this.retrivePerformance();
        },
        beforeDestroy() {
            //실시간 갱신여부 => false
            this.isContinue = false;
        },
    }
</script>